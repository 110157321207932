import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const LiquidityRemoveIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    viewBox="0 0 432 401"
    path="M 193.65 66.95
    L 182.6 51.25
    Q 169.15 32.6 157.75 18.05 154.35 13.7 151.1 9.7 146.7 4.25 142.65 -0.5 125.4 21.1 103.95 51.1 77.55 88.35 56.75 122.25 51.1 131.45 46 140.15
    L 46.25 140.15
    Q 40.903125 149.2541015625 36.15 157.8
    L 35.85 157.8
    Q 28.1 171.8 22.05 184.3 20.45 187.6 19 190.8 0 231.7 0 255.4 0 284.65 11.1 311.25 17.05 325.35 25.55 337.65 28.35 341.65 31.4 345.45 35.55 350.65 40.2 355.45 40.95 356.25 41.7 357 41.85 357.15 42 357.3 47.55 362.9 53.6 367.8 68.85 380.25 87.05 388 93.4673828125 390.769921875 100.05 392.85
    L 100.05 393
    Q 108.29765625 395.6150390625 116.8 397.15 129.4119140625 400 142.6 400 172.3 400 198.9 388.65 205.108203125 386.0486328125 211 382.85 226.0296875 374.8064453125 239 363.05 239.5015625 362.5998046875 240 362.15
    L 240 361.7
    Q 240.425 361.3013671875 240.85 360.9 243.4 358.45 244.65 357.15 262.3 339.45 272.75 317.15 274.1 314.3 275.3 311.4 276.2 309.3 277.05 307.15 279.05703125 301.9466796875 280.6 296.55
    L 159.15 296.55
    Q 135.1 296.55 135.1 272.65
    L 135.1 222.45
    Q 135.1 198.55 159.15 198.55
    L 270.75 198.55 269.3 195.15
    Q 265.4 186.55 260.7 177.15 258 171.8 255 166.15 248.4 153.65 240.4 139.9
    L 233.15 127.65
    Q 232.5 126.65 230.1 122.7 223.8 112.4 217.05 101.95 213.1 95.85 209.05 89.7 201.6 78.4 193.65 66.95
    M 50.85 196.55
    Q 56.6 194.25 63.2 196.25 65.8 196.95 68 198.2 71.3 200.05 73.25 203.1 76.85 208.4 74.25 215.2 63.1 247.4 71 275.45 73.45 284.25 77.7 292.6 83.1 303.25 91.45 313.45 97.65 320.9 105.3 328 110.55 333.4 109.7 339.75 109.2 342.75 107.6 345.5 107.6 345.6 107.55 345.65 106.05 347.95 103.75 349.95 100.1 353 95.9 354.1 94.85 354.3 93.85 354.5 92.9 354.6 91.85 354.65 90.1 354.7 88.45 354.45 82.95 353.55 78.6 349.55 64.65 336.35 55.05 321.9 42.55 303.1 37.25 282.2 34.904296875 273.050390625 34 263.8 34.012890625 263.675 34 263.55
    L 33.95 263.25
    Q 33.9078125 262.5498046875 33.85 261.8 33.85 261.6580078125 33.85 261.5 33.85 261 33.85 260.5 33.85 260 33.85 259.5 33.8 259.05 33.75 258.65 33.7 258.25 33.65 257.85 33.65 257.55 33.65 257.3 33.65 257 33.6 256.7 33.6 256.4 33.6 256.1 33.5578125 255.889453125 33.5 255.65 32.86015625 231.1482421875 42.3 205.9
    L 42.35 205.9
    Q 44.55 199.05 50.85 196.55
    M 71.85 346.25
    Q 71.8646484375 346.19609375 71.85 346.15
    L 72 346.3
    Q 71.9412109375 346.29296875 71.85 346.25
    M 77.05 378.45
    Q 77.05 378.3755859375 77.05 378.3 77.2228515625 378.3818359375 77.4 378.45
    L 77.05 378.45
    M 382.75 215.3
    Q 379.65 214.2 375.3 214.2
    L 169.7 214.2
    Q 153.3 214.2 153.3 230.5
    L 153.3 263.25
    Q 153.3 279.55 169.7 279.55
    L 375.3 279.55
    Q 379.45 279.55 382.75 278.4 383.8 278.05 384.65 277.55 391.55 273.9 391.55 263.25
    L 391.55 230.5
    Q 391.55 219.85 384.65 216.15 383.8 215.7 382.75 215.3 Z"
  />
);

export default LiquidityRemoveIcon;
