import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const MediumIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    viewBox="0 0 1024 1024"
    path="M341.142857 240.571429v670.285714q0 14.285714-7.142857 24.285714T313.142857 945.142857q-9.714286 0-18.857143-4.571428L28.571429 807.428571q-12-5.714286-20.285715-19.142857T0 761.714286V110.285714q0-11.428571 5.714286-19.428571t16.571428-8q8 0 25.142857 8.571428l292 146.285715q1.714286 1.714286 1.714286 2.857143z m36.571429 57.714285l305.142857 494.857143-305.142857-152V298.285714z m646.285714 10.285715v602.285714q0 14.285714-8 23.142857t-21.714286 8.857143-26.857143-7.428572l-252-125.714285z m-1.714286-68.571429q0 1.714286-146.571428 239.714286T704 758.285714L481.142857 396l185.142857-301.142857q9.714286-16 29.714286-16 8 0 14.857143 3.428571l309.142857 154.285715q2.285714 1.142857 2.285714 3.428571z"
  />
);

export default MediumIcon;
