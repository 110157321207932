export enum SELECTED_THEME {
  GRAY = 'gray',
  WHITE = 'white',
  ORANGE = 'orange',
  RED = 'red',
  BLUE = 'blue',
  PINK = 'pink',
  TURQUOISE = 'turquoise',
  NEON = 'neon',
  BANANA = '657447fa93684f04c4bad40c5adfb9aec1531e328371b1c7f2d45f8591dd7b56',
}
