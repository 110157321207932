import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const LiquidityAddIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    viewBox="0 0 432 401"
    path="M 240 139.8
    Q 235.673046875 132.4345703125 230.05 123.2 208.65 88.2 182.55 51.75 159.55 19.85 142.6 0 125.35 21.6 103.9 51.6 77.5 88.85 56.7 122.75 45.2966796875 141.3130859375 36.15 157.8
    L 35.85 157.8
    Q 28.1 171.8 22.05 184.3 20.45 187.6 19 190.8 0 231.7 0 255.4 0 284.65 11.1 311.25 17.05 325.35 25.55 337.65 28.35 341.65 31.4 345.45 35.55 350.65 40.2 355.45 40.95 356.25 41.7 357 41.85 357.15 42 357.3 47.55 362.9 53.6 367.8 68.85 380.25 87.05 388 98.75 393.05 111 395.85 112.25 396.15 113.55 396.4 127.7044921875 400 142.6 400 172.3 400 198.9 388.65 205.108203125 386.0486328125 211 382.85 226.0296875 374.8064453125 239 363.05 239.5015625 362.5998046875 240 362.15
    L 240 296.3 157.1 296.3
    Q 145.4 296.3 141.75 288.05 140.05 284.25 140.05 278.75
    L 140.05 215.8
    Q 140.1 213.8 140.35 212.05 142.3 198.85 157.1 198.85
    L 240 198.85 240 139.8
    M 50.85 196.55
    Q 56.6 194.25 63.2 196.25 65.8 196.95 68 198.2 71.3 200.05 73.25 203.1 76.85 208.4 74.25 215.2 63.1 247.4 71 275.45 73.45 284.25 77.7 292.6 83.1 303.25 91.45 313.45 97.65 320.9 105.3 328 110.55 333.4 109.7 339.75 109.2 342.75 107.6 345.5 107.6 345.6 107.55 345.65 106.05 347.95 103.75 349.95 100.1 353 95.9 354.1 94.85 354.3 93.85 354.5 92.9 354.6 91.85 354.65 90.1 354.7 88.45 354.45 82.95 353.55 78.6 349.55 64.65 336.35 55.05 321.9 42.55 303.1 37.25 282.2 34.904296875 273.050390625 34 263.8 34.012890625 263.675 34 263.55
    L 33.95 263.25
    Q 33.9078125 262.5498046875 33.85 261.8 33.85 261.6580078125 33.85 261.5 33.85 261 33.85 260.5 33.85 260 33.85 259.5 33.8 259.05 33.75 258.65 33.7 258.25 33.65 257.85 33.65 257.55 33.65 257.3 33.65 257 33.6 256.7 33.6 256.4 33.6 256.1 33.5578125 255.889453125 33.5 255.65 32.86015625 231.1482421875 42.3 205.9
    L 42.35 205.9
    Q 44.55 199.05 50.85 196.55
    M 72 346.3
    Q 71.9412109375 346.29296875 71.85 346.25 71.8646484375 346.19609375 71.85 346.15
    L 72 346.3
    M 131.55 364.95
    L 131.45 362.45
    Q 131.5119140625 362.471875 131.55 362.45
    L 131.55 364.95
    M 432 230.85
    Q 432 220.2 425.1 216.5 424.25 216.05 423.2 215.65 420.1 214.55 415.75 214.55
    L 324.4 214.55 324.4 121.65
    Q 324.4 105.4 308 105.4
    L 273.8 105.4
    Q 257.5 105.4 257.5 121.65
    L 257.5 214.55 172.15 214.55
    Q 155.75 214.55 155.75 230.85
    L 155.75 265.1
    Q 155.75 281.4 172.15 281.4
    L 257.5 281.4 257.5 380.1
    Q 257.5 396.4 273.8 396.4
    L 308 396.4
    Q 324.4 396.4 324.4 380.1
    L 324.4 281.4 415.75 281.4
    Q 419.9 281.4 423.2 280.25 424.25 279.9 425.1 279.4 432 275.75 432 265.1
    L 432 230.85 Z"
  />
);

export default LiquidityAddIcon;
