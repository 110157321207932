import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const MempoolIcon: React.FC<IconSvg> = ({ fill, bgColor, width, height }) => (
  <Svg fill={fill} bgColor={bgColor} width={width} height={height} viewBox="0 0 720 127">
    <defs>
      <g id="Layer1_0_FILL">
        <path
          fill="#B2B2B2"
          stroke="none"
          d="
M 91.05 118.3
Q 96.05 118.3 96.05 113.3
L 96.05 74.15 77.05 74.15 77.05 113.3
Q 77.05 118.3 82.05 118.3
L 91.05 118.3 Z"
        />
        <path
          fill="#999999"
          stroke="none"
          d="
M 109.45 74.15
L 96.05 74.15 96.05 113.3
Q 96.05 118.3 91.05 118.3
L 82.05 118.3
Q 77.05 118.3 77.05 113.3
L 77.05 74.15 1 74.15 1 111.3
Q 1 122.35 8.15 126.2
L 102.3 126.2
Q 109.45 122.35 109.45 111.3
L 109.45 74.15 Z"
        />
        <path
          fill="#868686"
          stroke="none"
          d="
M 91.05 32.65
L 82.05 32.65
Q 77.05 32.65 77.05 37.65
L 77.05 74.15 96.05 74.15 96.05 37.65
Q 96.05 32.65 91.05 32.65 Z"
        />
        <path
          fill="#666666"
          stroke="none"
          d="
M 82.05 32.65
L 91.05 32.65
Q 96.05 32.65 96.05 37.65
L 96.05 74.15 109.45 74.15 109.45 37.2
Q 109.45 20.2 92.45 20.2
L 18 20.2
Q 1 20.2 1 37.2
L 1 74.15 77.05 74.15 77.05 37.65
Q 77.05 32.65 82.05 32.65
M 719.2 37.75
L 708.95 37.75 708.95 53.25 666.75 53.25 666.75 11.75 677.5 11.25 677.5 1 656.25 1 656.25 64 719.2 64 719.2 37.75
M 719.2 32.25
L 719.2 1 687.7 1 698.2 11.25 682.45 27 693.25 37.75 708.95 22 719.2 32.25 Z"
        />
      </g>
      <g id="Layer1_1_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 1023.8 0
Q 1019.65 0 1016.15 0.25 1012.75 0.5 1012.2 0.8 1011.45 1.35 1011.25 18.45 1011.05 35.4 1011.2 85.1
L 1011.5 168.5 1034 169.1 1034 0 1023.8 0 Z"
        />
      </g>
      <g id="Layer1_2_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 193.7 79.9
Q 189.75 67.15 181.85 59.35 174 51.5 162.5 48.8 153.15 46.65 146.5 46.65 139.85 46.65 129.5 48.8 124.95 49.75 118.3 53.4 111.7 57 107.8 60.6 103.05 65 101.9 65 101.45 65 99.25 63.25 97.05 61.55 94.4 59.1 89.75 54.75 84.75 51.9 79.75 49 76.8 49 74.8 49 73.2 48.1 71.7 47.35 64.7 46.8 57.75 46.2 55 46.6 43.2 48.5 34.3 52.8 31.25 54.25 29.15 55.5 27 56.75 27 57.1 27 57.5 26.4 57.75 25.85 58 25 58 23.8 58 23.4 57.3 23 56.65 23 54.6 23 53.2 22.5 51.6 22 50 21.3 49.1 20 47.6 18.3 47.3 16.45 47 10 47.2
L 0.5 47.5 0 169 22.8 169 23.3 131.7
Q 23.6 106.4 23.95 99.65 24.35 92.8 25.6 89.2 29.05 79.8 37.75 73.9 46.5 68 56.9 68 67.85 68 75.45 73.5 83.05 79 85.3 88.5 85.65 90.15 86.15 102.5 86.6 114.65 86.9 130.5
L 87.5 168.5 108.8 169.1 109.2 132.3
Q 109.6 102.95 110.15 97.9 110.75 92.75 114.7 84 116 81.2 118.9 78.1 121.8 74.9 125 72.9 127.6 71.2 134.1 69.6 140.5 68 144.6 68 148.35 68 153.75 69.55 159.15 71.1 161.8 72.9 164.2 74.65 167.5 79.8 170.85 84.95 171.5 88 172.15 91.15 172.9 130
L 173.5 168.5 195.5 168.5 195.5 127
Q 195.5 99.4 195.2 91.55 194.9 83.65 193.7 79.9 Z"
        />
      </g>
      <g id="Layer1_3_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 119.3 191.4
Q 113.8 192.25 109.95 194 106.1 195.75 102.7 199 99.95 201.65 98.15 204.5 96.4 207.35 95.15 211.15 94.15 214.1 94.1 220.3 94.05 226.5 95 229.3 97.35 236.55 101.45 240.9 105.55 245.2 112.65 248 114.1 248.65 119.15 249.35 122.2 249.75 123.95 249.75 125.7 249.75 128.55 249.35 134.15 248.55 137.45 247.05 140.8 245.5 144.45 242 145.9 240.6 146.3 240 146.65 239.4 146.35 238.85 145.8 237.65 143.45 235.1 141.05 232.6 140.55 232.6 140.3 232.6 139.45 233.2 138.6 233.85 137.65 234.7 135.45 236.75 133.2 237.8 131 238.85 127.65 239.35 124.3 239.8 120.7 239.4 117 238.95 114.6 237.8 111.35 236.2 108.55 232.65 105.8 229.15 105.5 226.25
L 105.35 224.8 149.2 224.35 149.55 222.25
Q 149.9 220.35 149.45 216.25 149 212.1 148.3 209.95 147.7 208.35 146.85 206.5 146 204.6 145.7 204.3 145.5 204.1 144.1 202.1 142.85 200.3 140.45 198.15 138 196 136 195 131.25 192.5 129.6 192.5 128.6 192.5 127.85 192.1 127.15 191.75 123.85 191.45 120.55 191.2 119.3 191.4
M 123.95 201.4
Q 126.8 201.7 128.2 202.25 130.6 203.3 132.7 204.9 134.8 206.55 136 208.3 137.2 210.15 138.1 212.6 139.05 215 138.7 215.35 138.5 215.6 122.1 215.6 105.75 215.65 105.5 215.4 104.85 214.75 106.35 211.5 107.8 208.25 109.55 206.25 111.05 204.55 113.9 203.15 116.75 201.75 119.5 201.35 121.15 201.1 123.95 201.4 Z"
        />
      </g>
      <g id="Layer1_4_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 177.65 194.05
Q 174.15 195.6 173.7 196.35 173.6 196.5 173.25 196.65 172.9 196.75 172.55 196.75 172 196.75 171.85 196.4 171.65 196.05 171.65 194.95 171.65 192.65 170.7 192.1 169.8 191.55 166 191.65
L 161.5 191.8 161.25 249.15 171.85 248.85 172.35 213.05 173.9 209.8
Q 176.4 204.8 181.55 202.7 186.7 200.6 192.65 202.2 197.8 203.5 200.25 208.45
L 201.6 211.15 201.85 249.1 212.6 249.1 212.9 213.5 214.45 210.45
Q 216.3 206.9 218.25 205.15 220.25 203.45 223.85 202.25 229.15 200.6 234.1 202.4 239.1 204.2 241.15 208.55 241.85 210.05 242.05 213.1 242.2 216.1 242.4 229.75
L 242.65 248.85 253.5 248.85 253.5 230.5
Q 253.5 219.95 253.35 215.5 253.2 210.85 252.8 209.25 250.9 200.7 245.85 196.45 240.75 192.2 231.5 191.35 229.25 191.1 225.45 191.75 221.65 192.4 218.8 193.5 217.1 194.15 214.7 195.75 212.3 197.3 211.05 198.6 209.6 200.05 209.1 200.05 208.85 200.05 208.15 199.5 207.4 198.95 206.7 198.1 205.05 196.35 202.1 194.65 199.1 192.95 196.5 192.25 194.35 191.7 191.2 191.45 188.1 191.1 186.95 191.4 184.75 191.85 183.2 192.15 181.15 192.55 177.65 194.05 Z"
        />
      </g>
      <g id="Layer1_5_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 683.05 56.35
Q 679.65 54.2 672.9 51 668.55 48.9 657.9 47.3 647.25 45.7 643.5 46.6
L 636 48.1
Q 631.4 49 624.7 52.05 617.9 55.1 614.7 57.7 611.95 59.95 610.95 59.25 610 58.6 610 54.5 610 49.55 607.9 48.3 605.8 47 597.5 47 591.25 47 589.5 47.3 587.65 47.65 587 48.9 586 50.75 586 129.9 586 182.4 586.3 196.05 586.55 209.35 587.6 210.4 588.4 211.3 590.6 211.65 592.7 212 597.3 212 600.6 212 603.75 211.8 606.8 211.65 607.8 211.4
L 610 210.8 610 184.4
Q 610 173 610.25 165.35 610.5 157.8 610.9 157.5 611.35 157.3 613.15 158.25 614.9 159.25 617.1 160.9 619.25 162.55 622.75 164.4 626.15 166.2 628.9 167.2 633.3 168.7 636.85 169.1 640.5 169.5 650 169.4 659.9 169.35 663.25 168.9 666.8 168.5 671.3 166.7 674.1 165.65 677.6 163.9 681.15 162.15 683.3 160.7 686.75 158.35 689.4 155.95 692 153.55 697.1 148 701.4 143.35 704.7 135.1 708 126.8 709.2 117.7 711.4 101.35 706.45 86.8 701.55 72.25 690.3 62 686.35 58.45 683.05 56.35
M 668.6 73.4
Q 671.55 75.3 675.3 78.9 684.45 87.65 686.5 100.25 688.6 112.75 683.4 127.5 681.8 131.8 677.15 136.65 672.4 141.55 667.1 144.4 653.85 151.35 638.85 147.95 623.9 144.5 616.4 132.8 612.7 127.1 611.35 121.15 609.95 115.2 610.3 106.8 610.85 92.8 616.5 84 622.15 75.2 633.7 70.4 639 68.25 647.5 67.95 655.95 67.7 660.6 69.5 665.65 71.55 668.6 73.4 Z"
        />
      </g>
      <g id="Layer1_6_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 849.05 124.6
Q 849.65 121.1 850 117.1 854.2 76.05 822.7 55.8 815.45 51.1 803.55 48.35 791.7 45.65 783 46.7 779.65 47.1 773.2 48.65 766.65 50.2 765.1 51 755.6 55.65 750.15 59.7 744.6 63.75 740.7 68.9 737.65 72.95 733.8 80.25 730 87.5 730 89.3 730 91 728.4 97.2 726.45 104.65 727.45 114.25 728.5 123.9 732.3 134.3 732.8 135.8 733.6 137.3 734.3 138.7 734.8 139.2 736 140.4 736 141.3 736 142.2 741 148.5 745.35 153.95 751.1 158.05 756.8 162.1 767.3 167.2 768.9 168 779.6 169.5 786.15 170.35 789.8 170.35 793.5 170.35 799.4 169.5 814.95 167.3 827.35 158.05 839.8 148.7 845.4 135 847.2 130.5 848.1 128.7 848.5 128 849.05 124.6
M 817.3 79.7
Q 823.5 86.65 826.3 96.7 827.95 102.8 827.95 108.65 827.9 114.55 826.1 120.9 823.7 129.45 818.8 135.5 813.85 141.55 806 145.6 802.2 147.6 799.65 148.05 797.15 148.5 789.5 148.5 781.7 148.5 779.1 148 776.55 147.55 771.8 145.2 760.4 139.6 754.3 127.2 752.35 123.15 751.95 120.4 751.5 117.6 751.5 108.5 751.5 99.2 751.9 96.55 752.3 93.75 754.4 89.5 758.15 81.85 764.05 76.9 769.9 71.95 779 68.7 781.45 67.8 790.1 68.15 798.7 68.45 801.8 69.5 811 72.7 817.3 79.7 Z"
        />
      </g>
      <g id="Layer1_7_FILL">
        <path
          fill="#575757"
          stroke="none"
          d="
M 986.1 97.1
Q 983.45 84.95 978.35 76 973.2 67.05 965.1 60.6 960.1 56.55 952.9 52.8 945.75 49 943.1 49 941.15 49 938.8 48.1 936.15 47.1 928.55 46.75 920.85 46.35 917.5 47 915.6 47.45 908.5 48.7 906.45 49.1 904.4 49.65 902.3 50.25 901.5 50.7
L 894.6 54.1
Q 892.6 55.05 889.25 57.55 886 59.95 883.3 62.4 874.75 70.25 870.25 78.95 865.75 87.6 864 99.4 863.1 105.3 863.15 108.55 863.2 111.9 864.3 118.8 866.1 129.6 869.95 137.5 873.8 145.45 880.7 152.4 883.5 155.2 886.1 157.45 888.7 159.75 889.5 160.1 891.8 161.15 894.9 163.1 898.9 165.5 903.6 166.95 908.3 168.35 915.8 169.4 921.55 170.3 925.1 170.3 928.65 170.35 934.8 169.5 945.2 168.1 952.9 164.6 960.55 161.1 967.9 154.4 970.8 151.9 975.9 144.8 981 137.75 981 136.4 981 135.35 982.6 132.5 985.15 127.6 986.35 115.75 987.5 103.85 986.1 97.1
M 905 72.9
Q 911.6 69.25 921.15 68.3 930.7 67.35 937.6 69.7 946.45 72.7 952.2 78.4 957.9 84.05 961.5 93.5 963.45 98.6 963.45 108.45 963.45 118.3 961.5 123.6 959.5 129.05 956.3 133.6 953.15 138.05 949.3 141 944.4 144.7 940.3 146.35 936.1 148 930.2 148.6 910.85 150.5 898.85 139.15 886.8 127.8 886.8 107.7 886.9 100.9 887.65 97.6 888.45 94.2 891.7 87 893 84.15 897.5 79.35 902 74.55 905 72.9 Z"
        />
      </g>
    </defs>
    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
      <use xlinkHref="#Layer1_0_FILL" />
    </g>
    <g transform="matrix( 0.453582763671875, 0, 0, 0.471649169921875, 150.7,26.2) ">
      <use xlinkHref="#Layer1_1_FILL" />
    </g>
    <g transform="matrix( 0.453582763671875, 0, 0, 0.471649169921875, 150.7,26.2) ">
      <use xlinkHref="#Layer1_2_FILL" />
    </g>
    <g transform="matrix( 0.9617767333984375, 0, 0, 1, 159.95,-143.2) ">
      <use xlinkHref="#Layer1_3_FILL" />
    </g>
    <g transform="matrix( 0.9617767333984375, 0, 0, 1, 159.95,-143.2) ">
      <use xlinkHref="#Layer1_4_FILL" />
    </g>
    <g transform="matrix( 0.453582763671875, 0, 0, 0.471649169921875, 150.7,26.2) ">
      <use xlinkHref="#Layer1_5_FILL" />
    </g>
    <g transform="matrix( 0.453582763671875, 0, 0, 0.471649169921875, 150.7,26.2) ">
      <use xlinkHref="#Layer1_6_FILL" />
    </g>
    <g transform="matrix( 0.453582763671875, 0, 0, 0.471649169921875, 150.7,26.2) ">
      <use xlinkHref="#Layer1_7_FILL" />
    </g>
  </Svg>
);

export default MempoolIcon;
