import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const PercentIcon: React.FC<IconSvg> = ({ fill = '#AAE0B5', bgColor, width, height, className }) => (
  <Svg
    className={className}
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    path="
    M 85.35 85.35
    Q 100 70.7 100 50 100 29.3 85.35 14.65 70.7 0 50 0 29.3 0 14.65 14.65 0 29.3 0 50 0 70.7 14.65 85.35 29.3 100 50 100 70.7 100 85.35 85.35
    M 70.2 22
    Q 72.3 22 73.75 23.45
    L 77.25 27
    Q 78.75 28.45 78.75 30.55 78.75 32.6 77.25 34.05
    L 34.35 77.05
    Q 32.85 78.5 30.8 78.5 28.75 78.5 27.25 77.05
    L 23.7 73.45
    Q 22.25 72 22.25 69.95 22.25 67.9 23.7 66.4
    L 66.65 23.45
    Q 68.1 22 70.2 22
    M 30.5 20.2
    Q 34.6 20.2 37.55 23.15 40.45 26.1 40.45 30.2 40.45 34.4 37.55 37.3 34.6 40.25 30.5 40.25 26.35 40.25 23.4 37.3 20.5 34.4 20.5 30.2 20.5 26.1 23.4 23.15 26.35 20.2 30.5 20.2
    M 63.4 77.3
    Q 60.5 74.4 60.5 70.25 60.5 66.1 63.4 63.15 66.35 60.25 70.5 60.25 74.6 60.25 77.6 63.15 80.5 66.1 80.5 70.25 80.5 74.4 77.6 77.3 74.6 80.2 70.5 80.2 66.35 80.2 63.4 77.3 Z"
    viewBox="0 0 100 100"
  />
);

export default PercentIcon;
