import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const PriceIcon: React.FC<IconSvg> = ({ fill = '#AAE0B5', bgColor, width, height, className }) => (
  <Svg
    className={className}
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    path="
    M 341.4 341.4
    Q 400 282.9 400 200 400 117.15 341.4 58.5 282.9 0 200 0 117.15 0 58.5 58.5 0 117.15 0 200 0 282.9 58.5 341.4 117.15 400 200 400 282.9 400 341.4 341.4
    M 244.35 84.6
    Q 246.5 85.5 251.75 87.95 265.65 94.6 276.65 107.85 287.65 121 293.1 137.7 294.05 140.55 294.85 143.9 295.65 147.4 295.8 149.2
    L 296 151.15 251.35 151.15 251.1 147.8
    Q 250.55 141.4 245.45 135.9 242 132.25 234.9 129.3 227.85 126.35 221.9 126.2
    L 218.85 126.05 218.65 178 229.5 178.85
    Q 235.15 179.1 242.45 180.65 249.7 182.15 255.1 184.1 266.9 188.55 275.6 194.95 284.25 201.35 291.75 211.2 292.55 212.35 295.45 218.05 298.3 223.75 298.55 224.85
    L 299.8 230.05
    Q 301.85 238.75 300.8 248.5 299.75 258.3 295.7 267.25 294.55 269.85 291.65 274.65 288.7 279.45 287.3 281.1 286.5 281.95 284.25 284.8 282.45 286.95 277.75 291.1 273.1 295.2 270.4 297 268 298.65 267.1 299.4 266.35 299.95 265.7 300.3
    L 261.3 302.75
    Q 257.95 304.65 253.5 306.8 249.05 308.9 248.4 308.9 247.7 308.9 247.4 309.35 247.25 309.5 246.65 309.75 246.4 309.75 245.55 310.1 242.1 311.55 235 313.15 227.95 314.75 223.3 315.1
    L 218.85 315.4 218.65 349.75 179.85 349.75 179.85 316.15 175.75 316.15
    Q 172.3 316.15 168.1 315.7 163.85 315.2 161.5 314.55 157.65 313.5 153.4 312.05 149.1 310.6 147.8 309.9 145.95 308.9 145.55 308.9 145.05 308.9 139.8 305.8 134.6 302.65 132.25 300.95 127.4 297.45 122.75 293.1 118.15 288.75 115.3 285.1
    L 112.7 281.75
    Q 112.1 281 109.9 277.1 107.75 273.3 106.75 271.15 104.15 265.6 102.35 259 100.55 252.4 99.9 246
    L 99.6 242.7 142 242.45 142.9 245.3
    Q 143.65 247.9 145.55 251.75 147.35 255.65 147.8 255.65 148.15 255.65 148.55 256.65 149.15 258.65 154.85 262.95 160.5 267.2 163.25 267.75 163.75 267.95 164.05 268.15 165.05 268.75 168.85 269.55 172.7 270.3 175.6 270.5
    L 179.85 270.8 179.85 217.45 177.15 217.1
    Q 170.85 216.35 164.3 215.2 157.8 214 155.5 213.25 154.1 212.7 149.3 211.4 148.85 211.35 146.6 210.6 144.55 209.9 143.35 209.45 142 208.9 136 205.9 130 202.9 128.9 202.2 127.6 201.35 126.8 200.75 125.85 200.05 122.95 197.7 113.65 190.2 108.1 179.9 102.65 169.65 101.2 157.1 100.5 150.8 100.75 145.8 101.05 140.9 102.4 135.35 102.7 134.1 104.2 130.7 105.65 127.4 106.95 124.9 112.95 113.85 124.45 103.5 136 93.15 147.75 88.35
    L 152.15 86.6
    Q 156.05 84.9 161.65 83.6 167.35 82.3 172.75 81.85
    L 179.2 81.35
    Q 179.6 81.35 179.7 78.05 179.85 74.65 179.85 65.55
    L 179.85 49.75 218.65 49.75 218.65 64.5
    Q 218.65 74.8 218.75 76.95 218.9 79.3 219.55 79.5 220.35 79.75 225.8 80.3 228 80.55 230.8 81 233.5 81.45 235.1 81.9 239.25 82.95 239.55 83.1 242.1 83.7 244.35 84.6
    M 254.7 238.1
    Q 251.85 232.4 245.3 228.7 238.75 225 230.3 224.35
    L 222.75 223.75
    Q 219.3 223.6 218.95 223.85 218.65 224.25 218.65 247.5
    L 218.65 270.65 223.2 270.6
    Q 234.15 270.5 242.95 266.8 251.75 263.05 254.85 257.1 256.6 253.8 256.55 247.75 256.5 241.75 254.7 238.1
    M 179.85 124.25
    L 175.35 124.3
    Q 165.25 124.45 157.75 128.55 150.15 132.6 148 139.05 147 142.2 147 147 147 150.5 147.25 151.7 147.5 152.9 148.75 155.2 150.1 157.7 153.2 160.9 156.25 164.05 158.35 165.1 159.75 165.8 160.2 166.2 160.4 166.5 163.95 167.95 167.5 169.35 168.65 169.65 171.6 170.3 173.2 170.55 174.85 170.8 176.9 170.8
    L 179.85 170.8 179.85 124.25 Z"
    viewBox="0 0 400 400"
  />
);

export default PriceIcon;
