import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const LbtcIcon: React.FC<IconSvg> = ({ fill = '#46beae', bgColor, width, height, className }) => (
  <Svg className={className} fill={fill} bgColor={bgColor} width={width} height={height} viewBox="0 0 44 44">
    <path
      d="m20.7936 43.5016c-7.2026-.3034-13.87898-5.1776-15.92221-11.8063-1.58538-5.1248-.73119-10.0518 1.82456-14.7348 1.86556-3.4099 4.55115-6.1933 7.35295-8.87774 2.4464-2.34147 5.009-4.57741 7.2572-7.103556.3622-.402337.6629-.857439 1.2505-.184679 2.8428 3.271455 6.2459 6.021865 9.28 9.121835 2.8223 2.88894 5.3097 5.98224 6.7652 9.74844 3.2665 8.4754.5126 17.6434-8.4462 22.0955-2.8906 1.4247-5.5489 1.9655-9.362 1.7413z"
      fill="#46beae"
    />
    <path
      d="m29.5831 23.7196c.2733-1.827-1.0319-3.7595-3.5672-4.5972l.6629-2.5855-2.0091-.4815-.6423 2.5064c-.5262-.1253-1.0729-.244-1.6059-.3628l.6492-2.5261-2.0091-.4815-.6628 2.5855-3.6355-.864-.533 2.071s1.4897.3298 1.4556.3496c.8132.1979.9567.7123.9362 1.1278l-2.2414 8.7129c-.1025.2375-.3486.5936-.9157.4617.0205.0264-1.4624-.3496-1.4624-.3496l-.9909 2.2162 3.6286.8838-.7243 2.823 2.0022.4814.7175-2.7899c.5467.1451 1.0797.277 1.5991.3957l-.7175 2.7768 2.009.4881.7244-2.8164c3.4236.62 6.0272-.2374 6.6627-2.7174.5193-2.0249.0546-3.5089-1.4419-4.5972.9909-.31 1.8997-1.2795 2.1116-2.7108zm-4.1138 6.0614c-.6151 2.4074-4.8108 1.1081-6.1707.7849l1.0933-4.2674c1.3599.3298 5.7197.9762 5.0774 3.4825zm.615-6.2395c-.5672 2.1898-4.0591 1.0817-5.1935.8113l.9909-3.8651c1.1412.2704 4.7834.7717 4.2026 3.0538z"
      fill="#fff"
    />
  </Svg>
);

export default LbtcIcon;
