import React from 'react';
import Svg from '../Svg';
import { IconSvg } from './IconSvg';

const TickIcon: React.FC<IconSvg> = ({ fill = '#838588', bgColor, width, height }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    width={width}
    height={height}
    path="M 85.35 85.35
    Q 100 70.7 100 50 100 29.3 85.35 14.65 70.7 0 50 0 29.3 0 14.65 14.65 0 29.3 0 50 0 70.7 14.65 85.35 29.3 100 50 100 70.7 100 85.35 85.35
    M 77.4 32.8
    Q 79.45 34.85 80 35.5 80.5 36.15 80.6 36.8 80.8 37.7 80.75 38.1 80.6 38.8 80.6 39 80.5 39.35 76.85 43.1 73.25 46.8 63.55 56.5 46.6 73.4 46.05 73.65 45.45 73.95 44.6 73.95 43.75 73.9 43 73.65 42.4 73.35 32.85 63.95 23.3 54.55 22.65 53.45 22.3 52.9 22.2 52.55 22.05 52.1 22.05 51.5 22.1 51 22.2 50.55 22.25 50.1 22.35 49.9 22.7 49.25 25.8 46.2 28.9 43.15 29.4 42.95 30.05 42.65 30.95 42.7 31.8 42.75 32.55 43.1 33.35 43.45 38.9 49.15
    L 44.45 54.85 57.1 42.2
    Q 69.85 29.5 70.5 29.25 71.25 28.95 72.05 28.95 72.85 29 73.55 29.35 74 29.55 75 30.45 76.1 31.4 77.4 32.8 Z"
    viewBox="0 0 100 100"
  >
    <path
      fill="#000000"
      stroke="none"
      d="
M 259.4 33
Q 258.75 32.25 256.35 29.8 254.8 28.2 253.55 27.05 252.35 26 251.85 25.75 251 25.35 250.05 25.3 249.1 25.3 248.25 25.65 247.45 25.95 232.45 40.9
L 217.55 55.8 211 49.1
Q 204.45 42.4 203.5 41.95 202.65 41.55 201.65 41.5 200.6 41.45 199.8 41.8 199.2 42.05 195.55 45.65 191.9 49.25 191.5 50 191.4 50.2 191.3 50.75 191.2 51.3 191.15 51.85 191.15 52.6 191.3 53.1 191.45 53.55 191.85 54.2 192.65 55.45 203.9 66.55 215.1 77.65 215.85 78 216.7 78.3 217.7 78.35 218.7 78.35 219.4 78 220.1 77.7 240.05 57.75 251.45 46.35 255.7 41.95 260 37.55 260.1 37.15 260.1 36.9 260.3 36.05 260.35 35.6 260.15 34.55 260 33.8 259.4 33 Z"
    />
  </Svg>
);

export default TickIcon;
